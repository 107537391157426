import React from 'react'
import Price from '~components/Common/Price/Price'

import './BasketDetailsHeader.scss'

const BasketDetailsHeader = ({ count, total }) => {
	return (
		<div className="BasketDetailsHeader">
			<div>
				Total : {count} article{count > 1 ? 's' : ''}
			</div>

			<div>
				<Price value={total} />
			</div>
		</div>
	)
}

export default BasketDetailsHeader
