/*
 * Used to display price well formatted.
 * Returns a simple string.
 */
import React from 'react'

const Price = ({ value }) => {
	return <>{formatLocalePrice(value)}</>
}

export default Price

const formatLocalePrice = (price) => {
	return new Intl.NumberFormat('fr-FR', {
		style: 'currency',
		currency: 'EUR'
	})
		.format(price)
		.replace(',00', '')
		.replace(/\u00a0/g, '')
}
