export const order = (data) => {
	return fetch(`${process.env.GATSBY_API_URI}/order`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'x-universe-token': process.env.GATSBY_CLIENT_TOKEN,
			'x-website-token': process.env.GATSBY_X_WEBSITE_TOKEN
		},
		body: JSON.stringify(data)
	}).then((response) => response.json())
}
