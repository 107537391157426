import React from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import Footer from './Footer/Footer'
import TopBar from './TopBar/TopBar'

const useTemplate = (Component) => {
	const TemplateComponent = (props: any) => {
		const globalContext = useGlobalContext()

		return (
			<>
				<TopBar
					key="template-topbar"
					data={globalContext.website.theme.topbar}
				/>
				<Component {...props} />
				<Footer key="template-footer" />
			</>
		)
	}

	return TemplateComponent
}

export default useTemplate
