import React, { Fragment } from 'react'
import {
	computeTicketGroupDeliveryFees,
	getTicketOptionChoiceLabel,
	getTicketOptionLabel,
	getTicketPricingLabel
} from '@coq-trotteur/coq-core'
import {
	TMarketItemPricingMultiple,
	TMarketItemPricingUnique,
	TReservationTicket,
	TReservationTicketOptionalOption,
	TReservationTicketRequiredOption
} from '@coq-trotteur/coq-types'
import Price from '~components/Common/Price/Price'
import { getPictureURL } from '~utils/cloudinary'
import { formatPrice } from '~utils/misc'

import './BasketDetailsTicket.scss'

const BasketDetailsTicket = ({ ticket, handleRemove }) => {
	const { item } = ticket

	return (
		<div className="BasketDetailsTicket">
			<div className="section">
				<div className="item-details">
					<div className="miniature">
						<img
							src={
								getPictureURL(item.item.miniature, {
									w: 70,
									h: 70,
									c: 'fill'
								}) || '/images/empty.png'
							}
						/>
					</div>
					<div className="title">{item.item.title}</div>
				</div>
			</div>

			<div className="section">
				<BasketDetailsTicketItems item={item} />
			</div>

			{item.ticket_type === 'product' && <hr />}

			<div className="section">
				<Shipping ticket={ticket.item} />
			</div>
			<hr />
			<div className="section">
				<BasketDetailsTicketTotal item={item} />
			</div>

			<BasketDetailsTicketFooter handleRemove={handleRemove} />
		</div>
	)
}

export default BasketDetailsTicket

const BasketDetailsTicketItems = ({ item }) => {
	return (
		<div>
			<TicketPricing item={item} />
			<TicketOptions item={item} />
		</div>
	)
}

const TicketPricing = ({ item }: { item: TReservationTicket }) => {
	if (item.content.unique === true)
		return (
			<div className="line-with-price">
				<div>
					<span className="count">{item.content.quantity}</span>
					Quantité
				</div>

				<div>
					{formatPrice(
						item.content.quantity *
							(item.snapshot.pricing as TMarketItemPricingUnique).basePrice
					)}
					€
				</div>
			</div>
		)

	return (
		<div>
			{item.content.list.map((listItem, i) => {
				return (
					<div key={i} className="line-with-price">
						<div>
							<span className="count">{listItem.quantity}</span>
							{getTicketPricingLabel(item, listItem.id)}
						</div>

						<div>
							{formatPrice(
								listItem.quantity *
									(item.snapshot
										.pricing as TMarketItemPricingMultiple).list.find(
										(e) => e.id === listItem.id
									).price
							)}
							€
						</div>
					</div>
				)
			})}
		</div>
	)
}

const TicketOptions = ({ item }: { item: TReservationTicket }) => {
	const requiredOptions = item.options.filter(
		(option) => option.type === 'required'
	) as TReservationTicketRequiredOption[]
	const optionalOptions = item.options.filter(
		(option) => option.type === 'optional'
	) as TReservationTicketOptionalOption[]

	if (requiredOptions.length === 0 && optionalOptions.length === 0) return null

	return (
		<div>
			{optionalOptions.length > 0 && (
				<div style={{ marginTop: 8 }}>
					{optionalOptions.map((option, i) => {
						const price = item.snapshot.options.find(
							({ id }) => id === option.id
						)?.price

						return (
							<div key={i} className="line-with-price" style={{ margin: 0 }}>
								<div>
									<span className="count">{option.quantity}</span>
									{getTicketOptionLabel(item, option.id)}
								</div>
								<div>{formatPrice(option.quantity * price)}€</div>
							</div>
						)
					})}
				</div>
			)}

			{requiredOptions.length > 0 && (
				<div style={{ marginTop: 8 }}>
					{requiredOptions.map((option, i) => (
						<div key={i} className="line-with-price" style={{ margin: 0 }}>
							{getTicketOptionLabel(item, option.id)}:{' '}
							{getTicketOptionChoiceLabel(item, option.id, option.value)}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

const BasketDetailsTicketTotal = ({ item }) => {
	return (
		<div className="BasketDetailsTicketTotal">
			<div>Total</div>
			<div>
				<Price value={item.total} />
			</div>
		</div>
	)
}

const BasketDetailsTicketFooter = ({ handleRemove }) => (
	<div className="BasketDetailsTicketFooter">
		<button onClick={handleRemove}>
			<img src="/images/icons/trash.svg" /> Supprimer
		</button>
	</div>
)

const Shipping = ({ ticket }) => {
	if (!ticket.delivery) return null
	// @ts-ignore
	const total = computeTicketGroupDeliveryFees({
		tickets: [ticket]
	})

	const getLabel = () => {
		const { type } = ticket.delivery
		return ['shippingoption', 'serviceweight'].includes(type)
			? 'Livraison postale'
			: type === 'takeaway'
			? 'Retrait sur place'
			: 'Livraison par le vendeur'
	}

	return (
		<Fragment>
			<div
				className="line-with-price row prestation"
				style={{
					padding: 0,
					margin: '-2px 0'
				}}
			>
				<div className="flex-1">{getLabel()}</div>
				<div>{formatPrice(total)}€</div>
			</div>
		</Fragment>
	)
}
