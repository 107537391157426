import { validate as validateEmail } from 'email-validator'
import { computeShippingPrice } from './shipping'

export const prepareDataToSend = ({
	firstname,
	lastname,
	email,
	phone,
	ccnumber,
	ccsecuritycode,
	ccdatemonth,
	ccdateyear
}) => {
	return {
		bank: {
			ccnumber: ccnumber.replace(/[^0-9]/g, ''),
			ccsecuritycode,
			ccdate: `${`0${ccdatemonth}`.substr(-2)}/${ccdateyear}`
		},
		informations: {
			firstname,
			lastname,
			email,
			phone
		}
	}
}

export const checkFormValidity = (data) => {
	const errors = []

	if (!validateEmail(data.email)) {
		errors.push('email')
	}

	if (data.firstname.length < 1) errors.push('firstname')
	if (data.lastname.length < 1) errors.push('lastname')
	if (data.phone.length < 8) errors.push('phone')
	if (data.ccnumber.length < 8) errors.push('ccnumber')
	if (data.ccsecuritycode.length < 3) errors.push('ccsecuritycode')
	if (!data.ccdatemonth) errors.push('ccdatemonth')
	if (!data.ccdateyear) errors.push('ccdateyear')

	if (errors.length) {
		throw {
			errors
		}
	}
}

/*
 * total price calculator
 */
export const computeTicketShippingActivityTotal = (tickets: any[]) => {
	let total = 0
	const weightsByService = {}

	tickets.forEach(({ delivery, ...ticket }) => {
		if (delivery?.type === 'owndelivery') {
			total += delivery.price
		} else if (delivery?.type === 'shippingoption') {
			if (delivery.extraCostEnabled) {
				total += delivery.price
			} else {
				let count = 0
				if (Array.isArray(ticket.marketPricings)) {
					count = ticket.marketPricings.reduce(
						(acc, cur) => acc + cur.quantity,
						0
					)
				} else if (!isNaN(ticket.marketPricings?.quantity)) {
					count = ticket.marketPricings.quantity
				} else {
					count = ticket.quantity
				}

				if (weightsByService[delivery.service]) {
					weightsByService[delivery.service] += delivery.weight * count
				} else {
					weightsByService[delivery.service] = delivery.weight * count
				}
			}
		}
	})

	Object.entries(weightsByService).forEach(
		([service, weight]: [string, number]) => {
			total += computeShippingPrice(service, weight)
		}
	)

	return total
}
