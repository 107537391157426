export const computeShippingPrice = (service: string, weight: number) => {
	const priceTable = {
		Colissimo: [
			{
				weight: 250,
				price: 4.95
			},
			{
				weight: 500,
				price: 6.45
			},
			{
				weight: 750,
				price: 7.35
			},
			{
				weight: 1000,
				price: 7.99
			},
			{
				weight: 2000,
				price: 9.15
			},
			{
				weight: 5000,
				price: 14.1
			},
			{
				weight: 10000,
				price: 20.5
			},
			{
				weight: 15000,
				price: 26
			},
			{
				weight: 30000,
				price: 28.55
			}
		],
		'Lettre suivie': [
			{
				weight: 20,
				price: 1.42
			},
			{
				weight: 100,
				price: 2.39
			},
			{
				weight: 250,
				price: 4.33
			},
			{
				weight: 500,
				price: 6.27
			},
			{
				weight: 3000,
				price: 8.21
			},
			{
				weight: 3020,
				price: 6.63
			},
			{
				weight: 3100,
				price: 12.02
			},
			{
				weight: 3250,
				price: 22.62
			},
			{
				weight: 3500,
				price: 30.83
			},
			{
				weight: 6000,
				price: 0
			}
		]
	}

	const currentTable = priceTable[service]

	if (!currentTable) return null

	for (let i = 0; i < currentTable.length; i++) {
		if (weight <= currentTable[i].weight)
			return Math.round(currentTable[i].price * 1.1 * 100) / 100
	}
	return null
}
