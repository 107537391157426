import React, { useState } from 'react'

import { useBasketContext } from '~contexts/basketContext'

import Container from '~components/Common/Container/Container'

import BasketDetails from './BasketDetails/BasketDetails'
import BasketForm from './BasketForm/BasketForm'
import Bank3DSecure from './Bank3DSecure/Bank3DSecure'

import SuccessfulPaiement from './SuccessfulPaiement/SuccessfulPaiement'

import './Basket.scss'

const Basket = () => {
	const basket = useBasketContext()
	const [orderValidationData, setOrderValidationData] = useState(null)
	const [paiementIsDone, setPaiementIsDone] = useState<boolean>(false)

	const handleOrder = (data) => {
		setOrderValidationData(data)
	}

	if (paiementIsDone) {
		return <SuccessfulPaiement />
	}

	if (orderValidationData) {
		return (
			<Bank3DSecure
				data={orderValidationData}
				onError={() => {
					//
				}}
				onSuccess={() => {
					basket.clear()
					setPaiementIsDone(true)
				}}
			/>
		)
	}

	return (
		<div className="Basket">
			<Container>
				<div>
					<BasketForm handleOrder={handleOrder} />
				</div>
				<div>
					<BasketDetails
						handleRemove={basket.removeItem}
						items={basket.items}
						total={basket.getTotal()}
					/>
				</div>
			</Container>
		</div>
	)
}

export default Basket
