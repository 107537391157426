import React, { useState } from 'react'

import { Button } from '@coq-trotteur/coq-common-components/Atoms/Button/Button'

import LockIcon from '../icons/LockIcon'

import BasketDetailsTicket from './BasketDetailsTicket/BasketDetailsTicket'
import BasketDetailsHeader from './BasketDetailsHeader/BasketDetailsHeader'

import ArrowIcon from '~components/Icons/ArrowIcon'

import './BasketDetails.scss'

const BasketDetails = ({ items, total, handleRemove }) => {
	return (
		<div className="BasketDetails">
			<BasketDetailsHeader count={items.length} total={total} />
			<BasketDetailsTickets items={items} handleRemove={handleRemove} />
			<BasketDetailsFooter />
		</div>
	)
}

const BasketDetailsTickets = ({ items, handleRemove }) => {
	const [currentTicketIndex, setCurrentTicketIndex] = useState(0)

	if (items.length === 0) return null
	if (items.length === 1) {
		const [ticket] = items
		return (
			<div className="BasketDetailsTickets">
				<BasketDetailsTicket
					key={ticket.id}
					ticket={ticket}
					handleRemove={() => {
						handleRemove(ticket)
					}}
				/>
			</div>
		)
	}

	const ticket = items[currentTicketIndex]

	const handlePrevious = () => {
		setCurrentTicketIndex(
			(currentTicketIndex - 1 + items.length) % items.length
		)
	}
	const handleNext = () => {
		setCurrentTicketIndex((currentTicketIndex + 1) % items.length)
	}

	return (
		<div className="BasketDetailsTickets">
			<div className="nav-button">
				<Button onClick={handlePrevious} style={{ textAlign: 'center' }}>
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<span
							style={{
								height: 22,
								display: 'inline-block'
							}}
						>
							<ArrowIcon direction="left" height={22} width={22} />
						</span>{' '}
						Précédent
					</span>
				</Button>
				<Button onClick={handleNext} style={{ textAlign: 'center' }}>
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						Suivant{' '}
						<span
							style={{
								height: 22,
								display: 'inline-block'
							}}
						>
							<ArrowIcon direction="right" height={22} width={22} />
						</span>
					</span>
				</Button>
			</div>

			<BasketDetailsTicket
				key={ticket.id}
				ticket={ticket}
				handleRemove={() => {
					handleRemove(ticket)
				}}
			/>
		</div>
	)
}

const BasketDetailsFooter = () => (
	<div className="BasketDetailsFooter">
		<LockIcon />
		<span>Paiement crypté et sécurisé</span>
	</div>
)

export default BasketDetails
