/* eslint-disable no-useless-escape */
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useSimpleForm } from '~utils/form'

import { computeReservationTotal } from '@coq-trotteur/coq-core'

import {
	checkFormValidity,
	computeTicketShippingActivityTotal,
	prepareDataToSend
} from './BasketForm.utils'

import BasketFormCreditCardField from './BasketFormCreditCardField/BasketFormCreditCardField'
import BasketFormField from './Common/BasketFormField/BasketFormField'

import { useBasketContext } from '~contexts/basketContext'

import { order } from '../Basket.queries'

import { useGlobalContext } from '~contexts/globalContext'

import './BasketForm.scss'
import { Button } from '@coq-trotteur/coq-common-components/Atoms/Button/Button'

const BasketForm = ({ handleOrder }) => {
	const { website } = useGlobalContext()

	const [field, getFormJSON, setErrors] = useSimpleForm([
		{
			name: 'firstname',
			value: ''
		},
		{
			name: 'lastname',
			value: ''
		},
		{
			name: 'email',
			value: ''
		},
		{
			name: 'phone',
			value: ''
		},
		{
			name: 'ccnumber',
			value:
				process.env.GATSBY_ENV === 'development' ? '5017 6740 0000 0002' : ''
		},
		{
			name: 'ccsecuritycode',
			value: '123'
		},
		{
			name: 'ccdatemonth',
			value: '01'
		},
		{
			name: 'ccdateyear',
			value: '2022'
		}
	])

	const [isLoading, setIsLoading] = useState(false)

	const basketData = useBasketContext()

	const handleSend = () => {
		setIsLoading(true)

		setTimeout(() => {
			try {
				checkFormValidity(getFormJSON())

				const form = prepareDataToSend(getFormJSON())

				const tickets = basketData.items.map((item) => item.item)

				const shipping = computeTicketShippingActivityTotal(tickets)
				// @ts-ignore
				const total = computeReservationTotal({ tickets })

				const data = {
					total: total + shipping,
					destination: null,
					website: website.id,
					discount: null,
					tickets,
					form
				}

				order(data)
					.then((result) => {
						handleOrder(result)
					})
					.catch(() => {
						//
					})
					.finally(() => setIsLoading(false))
			} catch (err) {
				setErrors(err.errors)
				setIsLoading(false)
			}
		}, 500)
	}

	return (
		<div className="BasketForm">
			<section>
				<h2>Informations requises</h2>

				<div className="form-grid">
					<BasketFormField title="Prénom" {...field('firstname')} />
					<BasketFormField title="Nom de famille" {...field('lastname')} />
					<BasketFormField
						title={
							<span>
								Mail <small>(nous enverrons vos billets dessus)</small>
							</span>
						}
						{...field('email')}
					/>
					<BasketFormField
						title={
							<span>
								Numéro <small>(en cas de problème)</small>
							</span>
						}
						{...field('phone')}
						onChange={(value) => {
							field('phone').onChange(value.replace(/[^0-9+\(\) ]/g, ''))
						}}
					/>
				</div>
			</section>

			<section>
				<h2>Paiement sécurisé et crypté</h2>

				<BasketFormCreditCardField field={field} />
			</section>

			<BasketFormFooter handleSend={handleSend} isLoading={isLoading} />
		</div>
	)
}

const BasketFormFooter = ({ handleSend, isLoading }) => (
	<div className="BasketFormFooter">
		<div className="cguv-accept">
			En validant, vous confirmez avoir lu et accepté nos{' '}
			<Link to="/cguv">
				<u>CGUV</u>
			</Link>
		</div>
		<Button
			className="valid"
			style={{
				width: '100%',
				height: 56,
				textAlign: 'center'
			}}
			theme="yellow"
			onClick={handleSend}
			disabled={isLoading}
		>
			{isLoading ? (
				<i className="fas fa-spinner fa-spin" />
			) : (
				'Valider la commande'
			)}
		</Button>
	</div>
)

export default BasketForm
